"use client"

type WindowWithDataLayer = Window & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLayer: Record<string, any>[]
}

declare const window: WindowWithDataLayer

export const pageview = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "home_page_view",
    })
  }
}

export const upperFindCarClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "upper_find_a_car_clicks",
    })
  }
}

export const middleFindCarClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "middle_find_a_car_clicks",
    })
  }
}

export const lowerFindCarClick = (brandName: string, priceRange: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      brand_name: brandName,
      price_range: priceRange,
      event: "lower_find_a_car_clicks",
    })
  }
}

export const allOffersClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "all_offers_clicks",
    })
  }
}

export const modelClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "model_clicks",
    })
  }
}

export const moreInfoModelClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "more_info_model_clicks",
    })
  }
}

export const showMoreCarsClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "hp_more_cars_clicks",
    })
  }
}

export const whatLookingForClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "what_you_looking_for_clicks",
    })
  }
}

export const lowerVideoClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "lower_video_clicks",
    })
  }
}

export const questionsClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "questions_clicks",
    })
  }
}

export const homePageModelNotFoundClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "hp_model_not_found_clicks",
    })
  }
}

export const allCarsClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "model_not_found_clicks",
    })
  }
}

export const buttonMenuClick = (buttonName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "menu_button_clicks",
      button_name: buttonName,
    })
  }
}

export const footerButtonClick = (buttonName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      button_name: buttonName,
      event: "footer_button_clicks",
    })
  }
}

export const modelsPageView = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "models_page_view",
    })
  }
}

export const filterClick = (brandName: string, priceRange: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      brand_name: brandName,
      price_range: priceRange,
      event: "filter_clicks",
    })
  }
}

export const resetFilterClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "reset_filter_clicks",
    })
  }
}

export const sortClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "sort_clicks",
    })
  }
}

export const modelsPageModelNotFoundClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "models_model_not_found_clicks",
    })
  }
}

export const modelPagePopupView = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "model_page_popup_view",
    })
  }
}

export const popupContactClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "popup_contact_clicks",
    })
  }
}

export const allModelsButtonClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "all_models_button_clicks",
    })
  }
}

export const modelTypeClick = (modelType: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_type: modelType,
      event: "model_clicks",
    })
  }
}

export const payDepositClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "pay_deposit_clicks",
    })
  }
}

export const modelPageView = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "model_page_clicks",
    })
  }
}

export const modelPageContactClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "model_page_contact_clicks",
    })
  }
}

export const modelPagePayDepositClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "model_page_pay_deposit_clicks",
    })
  }
}

export const financeCalculatorClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "finance_calculator_clicks",
    })
  }
}

export const technicalDataClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "techincal_data_clicks",
    })
  }
}

export const tiresDataClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "tires_data_clicks",
    })
  }
}

export const notifyMeClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "notify_me_clicks",
    })
  }
}

export const loginPopupView = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "login_popup_view",
    })
  }
}

export const otpScreenView = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "otp_screen_view",
    })
  }
}

export const purchasePageView = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "purchase_page_view",
    })
  }
}

export const paymentPageView = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "payment_page_view",
    })
  }
}

export const purchaseSuccessPageView = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "purchase_success_page_view",
    })
  }
}

export const smartAgentPhoneClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "smart_agent_phone_clicks",
    })
  }
}

export const smartAgentSendLead = (brandName: string, showroom: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      brand_name: brandName,
      showroom: showroom,
      event: "smart_agent_send_lead_clicks",
    })
  }
}

export const smartAgentLeadSuccess = (
  brandName: string,
  showroom: string,
  phoneNumber: string,
  email: string,
): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      brand_name: brandName,
      showroom: showroom,
      phone_number: phoneNumber,
      email: email,
      event: "smart_agent_lead_success",
    })
  }
}

export const smartAgentError = (brandName: string, showroom: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      brand_name: brandName,
      showroom: showroom,
      event: "smart_agent_error",
    })
  }
}

export const smartAgentPayDeposit = (brandName: string, showroom: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      brand_name: brandName,
      showroom: showroom,
      event: "smart_agent_pay_deposit_clicks",
    })
  }
}

export const smartAgentBackToHome = (brandName: string, showroom: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      brand_name: brandName,
      showroom: showroom,
      event: "smart_agent_back_to_hp_clicks",
    })
  }
}

export const faqButtonClick = (): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "hp_to_all_models_clicks",
    })
  }
}

export const soldOutModelClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "model_sold_out_view",
    })
  }
}

export const sendSoldOutModelClick = (modelName: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      event: "send_form_sold_out_clicks",
    })
  }
}
export const sendPurchaseSuccessPageView = (modelName: string, phoneNumber: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      phone_number: phoneNumber,
      event: "purchase_success_page_view",
    })
  }
}

export const sendHomepageFilter = (modelName: string, budget: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      budget: budget,
      event: "brand_and_budget_filter_hp",
    })
  }
}

export const sendSearchFilter = (modelName: string, budget: string): void => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      model_name: modelName,
      budget: budget,
      event: "car_brand_and_budget_filter",
    })
  }
}
